import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import FileUpload from './FileUpload';
import Footer from './Footer';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';

const App = () => {
  const location = useLocation();

  const hideFooterRoutes = ['/terms-of-service', '/privacy-policy'];

  const shouldShowFooter = !hideFooterRoutes.includes(location.pathname);

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<FileUpload />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      {shouldShowFooter && <Footer />}
    </div>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;