import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { motion } from 'framer-motion';
import { Analytics } from '@vercel/analytics/react'; // Import Vercel Analytics
import './FileUpload.css';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null); // State for error messages
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const validateFile = (file) => {
    if (file.type !== 'application/pdf') {
      setErrorMessage('Only PDF files are allowed.');
      return false;
    }
    setErrorMessage(null); // Clear any previous errors
    return true;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && validateFile(selectedFile)) {
      setFile(selectedFile);
      setDownloadUrl(null); // Reset download URL when a new file is selected
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && validateFile(droppedFile)) {
      setFile(droppedFile);
      setDownloadUrl(null); // Reset download URL when a new file is dropped
    }
  };

  const handleCaptcha = (token) => {
    setCaptchaToken(token); // Save the CAPTCHA token
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please select or drop a file first!');
      return;
    }
    if (!captchaToken) {
      alert('Please complete the CAPTCHA!');
      return;
    }

    setIsLoading(true); // Start the loading animation
    const formData = new FormData();
    formData.append('pdfFile', file);
    formData.append('captchaToken', captchaToken); // Send the CAPTCHA token

    try {
      const response = await axios.post(
        'https://pdf-to-word-now-ecdbeb733b16.herokuapp.com/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        }
      );

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      const url = window.URL.createObjectURL(blob);
      setDownloadUrl(url); // Set the download URL for the file
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false); // Stop the loading animation
    }
  };

  return (
    <>
      <div className="file-upload-wrapper">
        <motion.div
          className="hover-box"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h1
            className="header"
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            PDF to Word Converter
          </motion.h1>

          <motion.p
            className="subheader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Drag and drop your PDF file or click to select
          </motion.p>

          <motion.div
            className={`drop-zone ${isDragging ? 'dragging' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => document.getElementById('fileInput').click()}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            transition={{ duration: 0.2 }}
          >
            {file ? (
              <motion.p
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                {file.name}
              </motion.p>
            ) : (
              <p>Drag and drop your file here, or click to browse</p>
            )}
            <input
              type="file"
              id="fileInput"
              accept="application/pdf"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </motion.div>

          {/* Display error message */}
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          {/* Centered CAPTCHA */}
          <div className="recaptcha-wrapper">
            <ReCAPTCHA
              sitekey="6LdfV5UqAAAAANx_j93EAnA6FYYj7wBG4Bl5pGO7"
              onChange={handleCaptcha}
            />
          </div>

          <motion.div
            className="button-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            {!downloadUrl ? (
              <motion.button
                type="submit"
                onClick={handleSubmit}
                className={`upload-button ${isLoading ? 'loading' : ''}`}
                disabled={isLoading}
                whileHover={{ scale: isLoading ? 1 : 1.05 }}
                whileTap={{ scale: isLoading ? 1 : 0.95 }}
                animate={{ backgroundColor: isLoading ? '#888' : '#28a745' }}
                transition={{ duration: 0.3 }}
              >
                {isLoading ? 'Converting...' : 'Upload & Convert'}
              </motion.button>
            ) : (
              <motion.a
                href={downloadUrl}
                download="converted.docx"
                className="download-button"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                Download Word File
              </motion.a>
            )}
          </motion.div>
        </motion.div>
      </div>
      {/* Add Vercel Analytics */}
      <Analytics />
    </>
  );
};

export default FileUpload;