import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> [Insert Date]</p>

      <p>
        PDF to Word Converter ("we," "us," or "our") respects your privacy and is committed to protecting it through this Privacy Policy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [Insert Website URL] (the "Website") and use our services.
      </p>

      <p>
        By accessing or using our Website, you agree to the terms of this Privacy Policy. If you do not agree, please do not use our services.
      </p>

      <h2>1. Information We Collect</h2>
      <h3>1.1. Personal Information</h3>
      <ul>
        <li>Any personal information you choose to upload in documents</li>
      </ul>

      <h3>1.2. Non-Personal Information</h3>
      <ul>
        <li>Browser type and version</li>
        <li>IP address</li>
        <li>Operating system</li>
        <li>Referring website</li>
        <li>Usage data (e.g., pages viewed, time spent on pages)</li>
      </ul>

      <h3>1.3. Uploaded Files</h3>
      <p>
        Any files uploaded through our service are temporarily processed for conversion and not stored permanently.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>We use your information for the following purposes:</p>
      <ul>
        <li>To provide, maintain, and improve our services.</li>
        <li>To process your uploaded documents.</li>
        <li>To communicate with you, including updates and customer service.</li>
        <li>To analyze website usage and improve functionality.</li>
        <li>To comply with legal obligations.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell or share your personal information to third parties. However, we may share information:</p>
      <ul>
        <li>With service providers and vendors who assist in operating our Website and providing services.</li>
        <li>If required by law or legal processes.</li>
        <li>To protect our rights and property or the safety of others.</li>
      </ul>

      <h2>4. Cookies and Tracking Technologies</h2>
      <p>
        Our Website uses cookies and similar technologies to enhance your user experience. You can control the use of cookies through your browser settings.
      </p>

      <h2>5. Data Security</h2>
      <p>
        We implement reasonable security measures to protect your information. However, no method of transmission over the internet is entirely secure. We cannot guarantee the absolute security of your information.
      </p>

      <h2>6. Children's Privacy</h2>
      <p>
        Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided personal information, please contact us.
      </p>

      <h2>7. Your Privacy Rights</h2>
      <p>
        Depending on your location, you may have the following rights:
      </p>
      <ul>
        <li><strong>Access and Correction:</strong> Request access to your personal data or correct inaccuracies.</li>
        <li><strong>Data Deletion:</strong> Request the deletion of your personal information.</li>
        <li><strong>Opt-Out:</strong> Opt-out of specific uses of your data, such as marketing.</li>
      </ul>
      <p>
        If you are a California resident, you have additional rights under the <strong>California Consumer Privacy Act (CCPA)</strong>. To exercise these rights, please contact us.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Changes will be posted on this page with a revised effective date.
      </p>
    </div>
  );
};

export default PrivacyPolicy;