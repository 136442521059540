import React from 'react';

const TermsOfService = () => {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p><strong>Effective Date:</strong> [Insert Date]</p>

      <p>
        Welcome to PDF to Word Converter ("we," "us," or "our"). These Terms of Service ("Terms") govern your access to and use of our website, services, and tools (collectively, the "Services").
      </p>

      <p>
        By using our Services, you agree to these Terms. If you do not agree, you may not use the Services.
      </p>

      <h2>1. Use of Services</h2>
      <p>
        You agree to use our Services only for lawful purposes and in compliance with these Terms. You are responsible for your actions while using the Services and must not:
      </p>
      <ul>
        <li>Violate any laws, regulations, or third-party rights.</li>
        <li>Upload or distribute harmful or malicious files.</li>
        <li>Interfere with or disrupt the Services or servers.</li>
      </ul>

      <h2>2. User Accounts</h2>
      <p>
        You may be required to create an account to access certain features. You are responsible for maintaining the confidentiality of your account credentials and are liable for activities under your account.
      </p>

      <h2>3. Intellectual Property</h2>
      <p>
        The content, software, and features of our Services are protected by copyright, trademark, and other intellectual property laws. You may not copy, modify, distribute, or reverse-engineer any part of the Services without our written permission.
      </p>

      <h2>4. Uploaded Files</h2>
      <p>
        You retain ownership of any files you upload for conversion. We do not claim any rights to your files. Uploaded files are processed temporarily and deleted after conversion.
      </p>

      <h2>5. Disclaimers</h2>
      <p>
        Our Services are provided "as is" and "as available" without warranties of any kind, either express or implied. We do not guarantee that the Services will be error-free, secure, or uninterrupted.
      </p>

      <h2>6. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, we are not liable for any indirect, incidental, or consequential damages arising from your use of the Services. Our total liability for any claim is limited to the amount you paid, if any, for using the Services.
      </p>

      <h2>7. Changes to the Terms</h2>
      <p>
        We may modify these Terms from time to time. Changes will be effective upon posting. Continued use of the Services after changes are posted constitutes your acceptance of the new Terms.
      </p>

      <h2>8. Termination</h2>
      <p>
        We may suspend or terminate your access to the Services if you violate these Terms or engage in conduct that we deem harmful to the Services or other users.
      </p>

      <h2>9. Governing Law</h2>
      <p>
        These Terms are governed by the laws of the United States and the state of [Insert State]. Any disputes arising under these Terms shall be resolved exclusively in the courts of [Insert State].
      </p>
    </div>
  );
};

export default TermsOfService;